.section {
  position: relative;
  padding: 8px var(--side-margin);
  width: 100%;
  box-sizing: border-box;
  max-width: 2000px;
}

.alt {
  position: relative;
  background-color: white;
  color: var(--purple);
  font-weight: 600;
  padding: 48px var(--side-margin);
  text-align: center;
  margin: 24px 0;
  border: 16px solid var(--purple);
  max-width: 2000px;
}

.banner {
  position: relative;
  background-color: var(--green);
  color: black;
  padding: 8px var(--side-margin);
  padding-bottom: 24px;
  text-align: center;
}
