div.home {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: Calc(var(--nav-height) * 1.2) 0 0 0;
  min-height: 100vh;
  max-width: 100vw;
  box-sizing: border-box;
  overflow: hidden;

  background-image: url("../../assets/tree-8p.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 20vw;

  margin-bottom: -156px;
}

div.home-copy {
  padding-bottom: 200px;
  display: inline-block;
  max-width: 800px;
}

div.home-copy p {
  line-height: 1.8rem;
  margin-bottom: 3rem;
}

img.tree-bg {
  opacity: 0.1;
  position: absolute;
  right: -10%;
  top: 10%;
  width: 1000px;
}
