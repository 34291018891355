@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,800&display=swap);
:root {
  --purple: #683b93;
  --purple-rgb: 104, 59, 147;
  --green: #b2c657;
  --green-rgb: 178, 198, 87;
  --nav-height: 120px;
  --side-margin: 140px;
}
@media only screen and (max-width: 920px) {
  :root {
    --side-margin: 48px;
  }
}

@media only screen and (max-width: 600px) {
  :root {
    --side-margin: 16px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-weight: 600;
  margin: 0;
  margin-bottom: 12px;
  text-align: center;
  color: #b2c657;
  color: var(--green);
}

h2 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 2rem;
}

h3 {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #683b93;
  color: var(--purple);
  color: #b2c657;
  color: var(--green);
}

h4 {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
}

h6 {
  font-weight: 400;
  font-size: 0.8rem;
}

p {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
}

a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #b2c657;
  color: var(--green);
}

a.inline {
  font-size: 1rem;
  cursor: pointer;
  color: #683b93;
  color: var(--purple);
}

ul {
  padding-left: 0;
  list-style: none;
}

ul.bullet {
  display: flex;
  flex-direction: column;
  text-transform: none;
  font-size: 1rem;
  list-style: inherit;
  padding-left: 16px;
  list-style-position: outside;
}

strong {
  font-weight: 600;
}

button {
  background-color: #b2c657;
  background-color: var(--green);
  color: #683b93;
  color: var(--purple);
  padding: 24px 48px;
  border-radius: 48px;
  border: none;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
}

button:hover {
  box-shadow: 2px 2px 6px rgba(104, 59, 147, 0.2);
  box-shadow: 2px 2px 6px rgba(var(--purple-rgb), 0.2);
  color: white;
}

button:active {
  box-shadow: none;
}

nav {
  position: fixed;
  z-index: 10;
  width: 100vw;
  background-color: var(--purple);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 36px var(--side-margin);
  font-weight: 600;
  max-width: 2000px;
  width: inherit;
  box-sizing: border-box;
  /* max-height: 20vh; */
}

@media only screen and (max-height: 505px) {
  .wrapper {
    padding: 0 var(--side-margin);
    margin: 16px 0;
  }
}

.logo {
  max-height: 3rem;
  min-height: 2rem;
  max-width: 60vw;
  width: 300px;
  margin-left: -5px;
  cursor: pointer;
  fill: white;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  border-top: var(--purple) 8px solid;
}

.dropdown div.dropdown-content a {
  color: var(--purple);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 0.8rem;
}

.dropdown div.dropdown-content a:hover {
  background-color: var(--purple);
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

ul.nav-buttons {
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  list-style: none;
  text-transform: uppercase;
  overflow: hidden;
  margin: 0;
  padding: 0;
  padding-left: 24px;
  line-height: 1rem;
  width: 50%;
}

ul.nav-buttons li {
  margin: 0 8px;
  padding: 0;
}

ul.nav-buttons li.last-link {
  margin-right: 0;
}

a.nav-button {
  text-decoration: none;
  font-size: 0.8rem;
  white-space: nowrap;
}

a.nav-button:hover {
  color: var(--green);
  cursor: pointer;
}

.selected {
  color: var(--green);
}

svg.hamburger {
  width: 44px;
  min-height: 36px;
  cursor: pointer;
  fill: white;
  pointer-events: all;
  z-index: 11;
}

svg.hamburger:hover {
  fill: var(--green);
}

@media only screen and (min-width: 741px) {
  svg.hamburger {
    display: none;
  }
}

@media only screen and (max-width: 740px) {
  ul.nav-buttons {
    display: none;
  }
}

div.menu {
  position: fixed;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  background-color: white;
  padding: 16px 0;
  width: 80vw;
  height: 100vh;
  box-sizing: border-box;

  z-index: 100;
  box-shadow: -48px 0 60px rgba(var(--purple-rgb), 0.6);

  display: none;
}

div.menu > ul {
  overflow: hidden;
  overflow-y: scroll;
}

div.menu > ul > li {
  padding: 8px 16px;
  width: 100%;
  box-sizing: border-box;
}

div.menu ul > li:hover {
  background-color: var(--purple);
}

div.menu a {
  color: var(--purple);
}

div.menu a.selected {
  color: var(--green);
}

div.menu > ul > li:hover > a {
  color: white;
}
div.menu > ul > li.sub {
  padding-left: 24px;
  font-size: 0.8rem;
}

div.menu > ul > li.exit {
  justify-self: flex-end;
}

svg.exit {
  align-self: flex-end;
  width: 44px;
  min-height: 36px;
  cursor: pointer;
  fill: var(--purple);
  pointer-events: all;
  margin: 16px;
}

svg.exit:hover {
  fill: var(--green);
}

div.home {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: Calc(var(--nav-height) * 1.2) 0 0 0;
  min-height: 100vh;
  max-width: 100vw;
  box-sizing: border-box;
  overflow: hidden;

  background-image: url(../../static/media/tree-8p.5ebeca28.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 20vw;

  margin-bottom: -156px;
}

div.home-copy {
  padding-bottom: 200px;
  display: inline-block;
  max-width: 800px;
}

div.home-copy p {
  line-height: 1.8rem;
  margin-bottom: 3rem;
}

img.tree-bg {
  opacity: 0.1;
  position: absolute;
  right: -10%;
  top: 10%;
  width: 1000px;
}

.anchor {
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  z-index: -1;
  top: Calc(var(--nav-height) * -1);
  left: 0;
  visibility: hidden;
}

.section {
  position: relative;
  padding: 8px var(--side-margin);
  width: 100%;
  box-sizing: border-box;
  max-width: 2000px;
}

.alt {
  position: relative;
  background-color: white;
  color: var(--purple);
  font-weight: 600;
  padding: 48px var(--side-margin);
  text-align: center;
  margin: 24px 0;
  border: 16px solid var(--purple);
  max-width: 2000px;
}

.banner {
  position: relative;
  background-color: var(--green);
  color: black;
  padding: 8px var(--side-margin);
  padding-bottom: 24px;
  text-align: center;
}

.about-bev {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.about-bev .left {
  flex: 1 1 60%;
}

.about-bev .right {
  flex: 1 1 20%;
  align-self: start;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.headshot-wrap {
  text-align: center;
  display: inline;
  padding: 16px;
  background-color: var(--green);
}

@media only screen and (max-width: 350px) {
  .about-bev .right {
    padding: 16px 0;
  }
  .headshot-wrap {
    padding: 8px;
  }

  .headshot-wrap img {
    width: 100%;
  }
}

.headshot-wrap h2 {
  font-size: 1.5rem;
}

.headshot-wrap h6 {
  margin: 0;
}

.headshot-wrap img {
  max-height: 360px;
  margin: 0;
}

.card {
  padding: 8px 16px;
  margin: 12px;
  border-radius: 4px;
  box-shadow: 2px 2px 6px rgba(var(--purple-rgb), 0.4);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  transition: 1s;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 4px 4px 8px rgba(var(--purple-rgb), 0.4);
}

.card img.icon {
  height: 80px;
  width: auto;
}

.card p {
  text-align: left;
}

@media only screen and (min-width: 671px) {
  .deck {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(575px, 1fr));
    justify-items: center;
  }

  .half-deck {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(384px, 1fr));
    justify-content: center;
  }
  .mobile-deck {
    display: none;
  }
}

@media only screen and (max-width: 671px) {
  .deck {
    display: none;
  }

  .mobile-deck {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .card img.icon {
    height: 14vw;
  }
}

div.col2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

div.col2 div.right {
  align-self: flex-start;
}

img.green-biz-logo {
  width: 250px;
  margin-top: 24px;
}

.layout {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  padding-top: 120px;
  min-height: 100vh;
  max-width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 8px;
}

@media (max-height: 520) {
  .layout {
    padding-top: 60px;
  }
}

.growth {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

img.growing-tree {
  max-height: 150px;
}

img.icon {
  max-height: 40px;
  width: auto;
}

div.green-col {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 48px;
  -webkit-column-gap: 48px;
          column-gap: 48px;
}

div.green-col > div.green-right {
  background-color: var(--green);
  padding: 16px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1200px) {
  div.green-col {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 1050px) {
  div.green-col {
    grid-template-columns: 1fr;
  }

  .no-mobile {
    display: none;
  }
}

div#probono {
  padding-top: 8px;
  padding-bottom: 8px;
}

div.client-list {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

div.client-list ul {
  justify-self: start;
  min-width: 200px;
  max-width: auto;
  display: flex;
  flex-direction: column;
  margin: 0;
}

div.client-list ul li {
  min-height: 1rem;
  max-height: auto;
}

.left {
  grid-column: 1 / span 1;
}

.right {
  grid-column: 2 / span 1;
}

ul.client-list-dynamic {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  margin: 48px 0;
}

ul.client-list-dynamic li {
  padding: 0;
  margin: 8px 0;
}

div.foot {
  background-color: transparent;
  color: white;
  min-height: 200px;
  position: relative;
  /* padding: 24px var(--side-margin); */
  width: 100%;
  box-sizing: border-box;
  padding-top: 119px;
}

.foot ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding: 0;
  padding: 48px var(--side-margin) 48px var(--side-margin);
  margin: 0;
  background-color: var(--purple);
}

.foot ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  text-transform: none;
  margin: 0;
  margin-right: 8px;
  font-size: 0.7rem;
}

.foot ul li img {
  width: 2rem;
  margin: 0;
  margin-right: -4px;
  margin-top: 0;
}

img.tree {
  width: 100px;
  position: absolute;
  right: var(--side-margin);
  top: 0;
}

img.road {
  width: 100px;
  position: absolute;
  right: var(--side-margin);
  top: 119px;
}

.gg-seal {
  width: 100vw;
  background-color: var(--purple);
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
}

h1.gigantic {
  font-size: 20vw;
  color: var(--purple);
}

