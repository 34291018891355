div.foot {
  background-color: transparent;
  color: white;
  min-height: 200px;
  position: relative;
  /* padding: 24px var(--side-margin); */
  width: 100%;
  box-sizing: border-box;
  padding-top: 119px;
}

.foot ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding: 0;
  padding: 48px var(--side-margin) 48px var(--side-margin);
  margin: 0;
  background-color: var(--purple);
}

.foot ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  text-transform: none;
  margin: 0;
  margin-right: 8px;
  font-size: 0.7rem;
}

.foot ul li img {
  width: 2rem;
  margin: 0;
  margin-right: -4px;
  margin-top: 0;
}

img.tree {
  width: 100px;
  position: absolute;
  right: var(--side-margin);
  top: 0;
}

img.road {
  width: 100px;
  position: absolute;
  right: var(--side-margin);
  top: 119px;
}

.gg-seal {
  width: 100vw;
  background-color: var(--purple);
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
}
