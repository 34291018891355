.about-bev {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.about-bev .left {
  flex: 1 1 60%;
}

.about-bev .right {
  flex: 1 1 20%;
  align-self: start;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.headshot-wrap {
  text-align: center;
  display: inline;
  padding: 16px;
  background-color: var(--green);
}

@media only screen and (max-width: 350px) {
  .about-bev .right {
    padding: 16px 0;
  }
  .headshot-wrap {
    padding: 8px;
  }

  .headshot-wrap img {
    width: 100%;
  }
}

.headshot-wrap h2 {
  font-size: 1.5rem;
}

.headshot-wrap h6 {
  margin: 0;
}

.headshot-wrap img {
  max-height: 360px;
  margin: 0;
}

.card {
  padding: 8px 16px;
  margin: 12px;
  border-radius: 4px;
  box-shadow: 2px 2px 6px rgba(var(--purple-rgb), 0.4);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  transition: 1s;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 4px 4px 8px rgba(var(--purple-rgb), 0.4);
}

.card img.icon {
  height: 80px;
  width: auto;
}

.card p {
  text-align: left;
}

@media only screen and (min-width: 671px) {
  .deck {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(575px, 1fr));
    justify-items: center;
  }

  .half-deck {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(384px, 1fr));
    justify-content: center;
  }
  .mobile-deck {
    display: none;
  }
}

@media only screen and (max-width: 671px) {
  .deck {
    display: none;
  }

  .mobile-deck {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .card img.icon {
    height: 14vw;
  }
}

div.col2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

div.col2 div.right {
  align-self: flex-start;
}

img.green-biz-logo {
  width: 250px;
  margin-top: 24px;
}
