.growth {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

img.growing-tree {
  max-height: 150px;
}

img.icon {
  max-height: 40px;
  width: auto;
}

div.green-col {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 48px;
}

div.green-col > div.green-right {
  background-color: var(--green);
  padding: 16px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1200px) {
  div.green-col {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 1050px) {
  div.green-col {
    grid-template-columns: 1fr;
  }

  .no-mobile {
    display: none;
  }
}
