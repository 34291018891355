@import url("https://fonts.googleapis.com/css?family=Poppins:400,600,800&display=swap");

:root {
  --purple: #683b93;
  --purple-rgb: 104, 59, 147;
  --green: #b2c657;
  --green-rgb: 178, 198, 87;
  --nav-height: 120px;
  --side-margin: 140px;
}
@media only screen and (max-width: 920px) {
  :root {
    --side-margin: 48px;
  }
}

@media only screen and (max-width: 600px) {
  :root {
    --side-margin: 16px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-weight: 600;
  margin: 0;
  margin-bottom: 12px;
  text-align: center;
  color: var(--green);
}

h2 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 2rem;
}

h3 {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--purple);
  color: var(--green);
}

h4 {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
}

h6 {
  font-weight: 400;
  font-size: 0.8rem;
}

p {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
}

a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: var(--green);
}

a.inline {
  font-size: 1rem;
  cursor: pointer;
  color: var(--purple);
}

ul {
  padding-left: 0;
  list-style: none;
}

ul.bullet {
  display: flex;
  flex-direction: column;
  text-transform: none;
  font-size: 1rem;
  list-style: inherit;
  padding-left: 16px;
  list-style-position: outside;
}

strong {
  font-weight: 600;
}

button {
  background-color: var(--green);
  color: var(--purple);
  padding: 24px 48px;
  border-radius: 48px;
  border: none;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
}

button:hover {
  box-shadow: 2px 2px 6px rgba(var(--purple-rgb), 0.2);
  color: white;
}

button:active {
  box-shadow: none;
}
