.layout {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  padding-top: 120px;
  min-height: 100vh;
  max-width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 8px;
}

@media (max-height: 520) {
  .layout {
    padding-top: 60px;
  }
}
