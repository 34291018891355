nav {
  position: fixed;
  z-index: 10;
  width: 100vw;
  background-color: var(--purple);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 36px var(--side-margin);
  font-weight: 600;
  max-width: 2000px;
  width: inherit;
  box-sizing: border-box;
  /* max-height: 20vh; */
}

@media only screen and (max-height: 505px) {
  .wrapper {
    padding: 0 var(--side-margin);
    margin: 16px 0;
  }
}

.logo {
  max-height: 3rem;
  min-height: 2rem;
  max-width: 60vw;
  width: 300px;
  margin-left: -5px;
  cursor: pointer;
  fill: white;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  border-top: var(--purple) 8px solid;
}

.dropdown div.dropdown-content a {
  color: var(--purple);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 0.8rem;
}

.dropdown div.dropdown-content a:hover {
  background-color: var(--purple);
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

ul.nav-buttons {
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  list-style: none;
  text-transform: uppercase;
  overflow: hidden;
  margin: 0;
  padding: 0;
  padding-left: 24px;
  line-height: 1rem;
  width: 50%;
}

ul.nav-buttons li {
  margin: 0 8px;
  padding: 0;
}

ul.nav-buttons li.last-link {
  margin-right: 0;
}

a.nav-button {
  text-decoration: none;
  font-size: 0.8rem;
  white-space: nowrap;
}

a.nav-button:hover {
  color: var(--green);
  cursor: pointer;
}

.selected {
  color: var(--green);
}

svg.hamburger {
  width: 44px;
  min-height: 36px;
  cursor: pointer;
  fill: white;
  pointer-events: all;
  z-index: 11;
}

svg.hamburger:hover {
  fill: var(--green);
}

@media only screen and (min-width: 741px) {
  svg.hamburger {
    display: none;
  }
}

@media only screen and (max-width: 740px) {
  ul.nav-buttons {
    display: none;
  }
}

div.menu {
  position: fixed;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  background-color: white;
  padding: 16px 0;
  width: 80vw;
  height: 100vh;
  box-sizing: border-box;

  z-index: 100;
  box-shadow: -48px 0 60px rgba(var(--purple-rgb), 0.6);

  display: none;
}

div.menu > ul {
  overflow: hidden;
  overflow-y: scroll;
}

div.menu > ul > li {
  padding: 8px 16px;
  width: 100%;
  box-sizing: border-box;
}

div.menu ul > li:hover {
  background-color: var(--purple);
}

div.menu a {
  color: var(--purple);
}

div.menu a.selected {
  color: var(--green);
}

div.menu > ul > li:hover > a {
  color: white;
}
div.menu > ul > li.sub {
  padding-left: 24px;
  font-size: 0.8rem;
}

div.menu > ul > li.exit {
  justify-self: flex-end;
}

svg.exit {
  align-self: flex-end;
  width: 44px;
  min-height: 36px;
  cursor: pointer;
  fill: var(--purple);
  pointer-events: all;
  margin: 16px;
}

svg.exit:hover {
  fill: var(--green);
}
